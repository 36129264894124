import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  HStack,
  Heading,
  Icon,
  Image,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";

import { useWalletInfo, useWeb3Modal } from "@web3modal/wagmi/react";

import {
  getWcChainIcon,
  isChainSupported,
  shortenAddress,
} from "../utils/web3Functions";
import { WalletConnectIconComponent } from "./svgComponent/WalletConnectIconComponent";

import {
  AccountController,
  CoreHelperUtil,
  NetworkController,
} from "@web3modal/core";
import { useAccount } from "wagmi";

export const WalletConnectButton = () => {
  const { open } = useWeb3Modal();
  const { walletInfo } = useWalletInfo();
  const { address } = useAccount();
  const currentChainIcon = getWcChainIcon(
    NetworkController?.state?.caipNetwork
  );

  const walletConnectText = useBreakpointValue({
    base: "Connect",
    sm: "Connect",
    md: "Connect Wallet"
  }, {
    fallback: "md"
  })

  console.log("walletConnectText", walletConnectText);

  const walletAddressText = useBreakpointValue({
    base: shortenAddress(address, 3),
    sm: shortenAddress(address, 3),
    md: shortenAddress(address, 6)
  }, {
    fallback: "md"
  })

  const buttonText = address
    ? walletAddressText
    : walletConnectText;

  const SignInComponent = () => {
    return (
      <Button
        onClick={() => open()}
        borderRadius="full"
        colorScheme={useColorModeValue("twitter", "gray")}
        leftIcon={
          walletInfo?.icon ? (
            <Image src={walletInfo?.icon} boxSize={5}></Image>
          ) : undefined
        }
        borderWidth="thin"
        size={["md", "lg"]}
      >
        <HStack>
          {!address && (
            <Center boxSize={7}>
              <WalletConnectIconComponent />
            </Center>
          )}
          <Text>{buttonText}</Text>
          {/* <Icon as={ArrowForwardIcon}></Icon> */}
        </HStack>
      </Button>
    );
  };

  const ChainIconComponent = () => {
    return (
      <Tooltip label="Click to switch supported networks.">
        <Image
          src={currentChainIcon}
          boxSize={8}
          borderRadius="full"
          cursor="pointer"
          onClick={() =>
            open({
              view: "Networks",
            })
          }
        ></Image>
      </Tooltip>
    );
  };

  return (
    <HStack>
      {address && currentChainIcon !== undefined && <ChainIconComponent />}
      <SignInComponent />
    </HStack>
  );
};
