import { useColorMode } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { defaultWagmiConfig } from '@web3modal/wagmi';
import { createWeb3Modal, useWeb3ModalTheme } from '@web3modal/wagmi/react';
import { ReactNode, useEffect } from 'react';
import { WagmiProvider, http } from 'wagmi';
import {
  arbitrum,
  avalanche,
  base,
  bsc,
  bscTestnet,
  mainnet,
  polygon
} from 'wagmi/chains';

// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId from https://cloud.walletconnect.com
// export const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;
export const projectId = '2c30d08e136267898836c1e60b2b760b';

if (!projectId) {
  throw new Error('WALLET_CONNECT_PROJECT_ID is undefined.');
}

// 2. Create wagmiConfig
const metadata = {
  name: 'iFasst',
  description:
    'Web3 protocol to distribute rewards to by staking and referrals',
  url: 'https://ifasst.com', // origin must match your domain & subdomain
  icons: ['https://ifasst.com/logoIcon.svg'],
};

export const chains = [polygon] as const;

export type SupportedChainsType = {
  [key: string]: SupportedChainObjectType;
};

export type SupportedChainObjectType = {
  label: string;
  icon: string;
  color: string;
  // chain: Chain;
  // supported: boolean;
};

export const supportedChainObject: SupportedChainsType = {
  [`${mainnet.id}`]: {
    label: 'Ethereum Mainnet Chain',
    icon: './cryptoIcons/eth.svg',
    color: 'twitter.300',
    // chain: mainnet,
    // supported: true,
  },
  [`${polygon.id}`]: {
    label: 'Polygon',
    icon: './cryptoIcons/polygon.svg',
    color: 'purple.300',
    // chain: polygon,
    // supported: true,
  },
  [`${bsc.id}`]: {
    label: 'Binance Smart Chain',
    icon: './cryptoIcons/bsc.svg',
    color: 'yellow.300',
    // chain: bsc,
    // supported: true,
  },
  [`${arbitrum.id}`]: {
    label: 'Arbitrum',
    icon: './cryptoIcons/arb.svg',
    color: 'yellow.300',
    // chain: bsc,
    // supported: true,
  },
  [`${base.id}`]: {
    label: 'Base',
    icon: './cryptoIcons/base.svg',
    color: 'yellow.300',
    // chain: bsc,
    // supported: true,
  },
  [`${avalanche.id}`]: {
    label: 'Avalanche Chain',
    icon: './cryptoIcons/avax.svg',
    color: 'yellow.300',
    // chain: bsc,
    // supported: true,
  },
  [`${1399811149}`]: {
    label: 'Solana',
    icon: './cryptoIcons/sol.svg',
    color: 'yellow.300',
    // chain: bsc,
    // supported: true,
  },
};

export const getChainIcon = (chainId: number | undefined) => {
  if (chainId) {
    return supportedChainObject[chainId];
  }

  return undefined;
};

const defaultConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  // transports: {
  //   [polygon.id]: http(
  //     'https://virtual.polygon.rpc.tenderly.co/5a5e2ef3-0d1c-428c-8040-f8dc6eb313dd',
  //   )
  // },
  auth: {
    email: false, // default to true
    // socials: ['google', 'x', 'github', 'discord', 'apple', "farcaster"],
    showWallets: true, // default to true
    walletFeatures: true, // default to true
  },
});

createWeb3Modal({
  wagmiConfig: defaultConfig,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
  allowUnsupportedChain: true,
  defaultChain: polygon,
  // enableAuth: true,
  featuredWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369',
  ]
});

export function ProviderWeb3Modal({ children }: { children: ReactNode }) {
  const { setThemeMode } = useWeb3ModalTheme();
  const { colorMode } = useColorMode();

  useEffect(() => {
    setThemeMode(colorMode);
  }, [colorMode, setThemeMode]);

  return (
    <WagmiProvider config={defaultConfig}>
      <QueryClientProvider client={queryClient}>
        {children}
      </QueryClientProvider>
    </WagmiProvider>
  );
}
