import { useEffect } from 'react';
import { Flex, Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import React from "react";

export const TermsAndCondition = () => {
    // Scroll to top when the component is mounted
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
            <Flex w="full" gap={10} direction="column" px={5} py={5}>
        <Heading>
            Terms and Conditions for iFasst
        </Heading>
        <Text>
            Welcome to iFasst. By accessing or using the iFasst platform, you agree to be bound by the terms and conditions outlined below. These Terms and Conditions (referred to as “Terms”) govern your access to and use of our decentralized staking platform. Please read these Terms carefully before engaging with iFasst. If you do not agree to these Terms, you should not use our services.
        </Text>

        <Heading size="md" mb={-7}>
            1. Acceptance of Terms
        </Heading>
        <Text mb={-4}>
            By using iFasst, you agree to comply with these Terms and all applicable laws and regulations. iFasst reserves the right to update or modify these Terms at any time, and continued use of the platform signifies your acceptance of any changes. You are encouraged to review these Terms regularly to stay informed of any updates.
        </Text>

        <Heading size="md" mb={-7}>
            2. Platform Overview
        </Heading>
        <Text mb={-4}>
            iFasst is a decentralized staking platform built on the Polygon network. It allows users to stake their assets and earn rewards through decentralized smart contracts. By connecting your digital wallet (such as MetaMask or Trust Wallet), you can participate in the staking process and benefit from the rewards offered on our platform.
            <br />
            iFasst operates in a fully decentralized manner, meaning we do not control, manage, or hold any user funds. All staking and reward activities are handled via blockchain-based smart contracts, ensuring transparency and security.
        </Text>

        <Heading size="md" mb={-7}>
            3. User Responsibilities
        </Heading>
        <Text mb={-7}>
            As a user of iFasst, you agree to:
        </Text>
        <UnorderedList mb={-4}>
            <ListItem>Be of Legal Age: You must be of legal age in your jurisdiction to participate in decentralized finance activities and use our platform.</ListItem>
            <ListItem>Provide Accurate Information: If any personal information is voluntarily provided, you warrant that the information is accurate and up-to-date.</ListItem>
            <ListItem>Use the Platform Responsibly: You agree not to engage in illegal, malicious, or unethical behavior while using iFasst. This includes, but is not limited to, hacking, exploiting vulnerabilities, or engaging in fraudulent activities.</ListItem>
            <ListItem>Manage Your Wallet and Private Keys: You are fully responsible for the management of your wallet credentials, private keys, and funds. iFasst will never request your private keys, and you should never share them with anyone.</ListItem>
            <ListItem>Comply with Laws: You agree to comply with all applicable laws, regulations, and tax obligations in your jurisdiction when using iFasst.</ListItem>
        </UnorderedList>

        <Heading size="md" mb={-7}>
            4. No KYC (Know Your Customer)
        </Heading>
        <Text mb={-4}>
            iFasst is a decentralized platform that does not require Know Your Customer (KYC) verification. This means that you are not required to provide any personal identification or documentation to use our services. However, you are responsible for ensuring that your participation in decentralized finance activities is in compliance with the laws of your country or jurisdiction.
        </Text>

        <Heading size="md" mb={-7}>
            5. Staking and Rewards
        </Heading>
        <Text mb={-7}>
            By using iFasst’s staking services, you acknowledge and agree to the following:
        </Text>
        <UnorderedList mb={-4}>
            <ListItem>Wallet Connection: Staking requires you to connect a compatible digital wallet (e.g., MetaMask, Trust Wallet) to the platform.</ListItem>
            <ListItem>Smart Contracts: Staking and rewards distribution are handled through immutable smart contracts deployed on the blockchain. Once you initiate a transaction, it cannot be reversed or altered.</ListItem>
            <ListItem>APY (Annual Percentage Yield): iFasst offers a 60% APY for staking. This rate is subject to change depending on market conditions, platform performance, or smart contract updates.</ListItem>
            <ListItem>No Guarantee of Profit: While iFasst strives to provide attractive staking rewards, the platform does not guarantee any specific financial returns or profits. Users must be aware of the risks associated with decentralized finance and cryptocurrency investments.</ListItem>
            <ListItem>Market Risks: iFasst is not responsible for losses resulting from fluctuations in the value of staked assets or the failure of the underlying blockchain network (Polygon).</ListItem>
        </UnorderedList>

        <Heading size="md" mb={-7}>
            6. Fees
        </Heading>
        <Text mb={-4}>
            iFasst may charge fees for staking, withdrawals, or other platform activities. These fees are subject to change and will be clearly outlined on the platform. By using the platform, you agree to the applicable fees at the time of your transaction. Blockchain network fees (gas fees) may also apply when interacting with smart contracts, and these are beyond the control of iFasst.
        </Text>

        <Heading size="md" mb={-7}>
            7. Risk Disclosure
        </Heading>
        <Text mb={-7}>
            By using iFasst, you acknowledge and accept the risks associated with decentralized finance, including but not limited to:
        </Text>
        <UnorderedList mb={-4}>
            <ListItem>Volatility: The value of digital assets can fluctuate significantly. You should only stake what you can afford to lose.</ListItem>
            <ListItem>Smart Contract Risk: While iFasst’s smart contracts are designed to be secure, bugs or vulnerabilities may exist, which could potentially result in the loss of funds.</ListItem>
            <ListItem>Blockchain Network Risk: Network congestion, transaction delays, and other issues may affect the performance of the platform.</ListItem>
            <ListItem>Security Risks: You are responsible for the security of your digital wallet, private keys, and the device you use to access iFasst. iFasst is not liable for any losses resulting from security breaches, phishing attacks, or unauthorized access to your wallet.</ListItem>
        </UnorderedList>

        <Heading size="md" mb={-7}>
            8. Security Measures
        </Heading>
        <Text mb={-7}>
            iFasst is committed to maintaining a secure platform, but the decentralized nature of blockchain technology requires users to take personal responsibility for securing their wallets and private keys. You agree to:
        </Text>
        <UnorderedList mb={-4}>
            <ListItem>Use strong, unique passwords for your digital wallets.</ListItem>
            <ListItem>Enable two-factor authentication (2FA) where available.</ListItem>
            <ListItem>Avoid sharing sensitive wallet information, such as private keys or seed phrases.</ListItem>
            <ListItem>Regularly update and secure your devices to prevent malware, phishing, or hacking attacks.</ListItem>
        </UnorderedList>

        <Heading size="md" mb={-7}>
            9. No Liability for Losses
        </Heading>
        <Text mb={-7}>
            By using iFasst, you understand and agree that:
        </Text>
        <UnorderedList mb={-4}>
            <ListItem>iFasst is not responsible for any financial losses, damages, or liabilities you may incur as a result of using the platform.</ListItem>
            <ListItem>iFasst cannot reverse or modify transactions once they are recorded on the blockchain.</ListItem>
            <ListItem>iFasst is not responsible for any losses resulting from blockchain network issues, such as delays, technical failures, or malicious attacks on the network.</ListItem>
        </UnorderedList>

        <Heading size="md" mb={-7}>
            10. Third-Party Services
        </Heading>
        <Text mb={-4}>
            iFasst may provide links to or integrate third-party services, decentralized applications (dApps), or blockchain platforms. These third-party services operate independently of iFasst, and we are not responsible for their performance, security, or data handling practices. Users are advised to review the terms and conditions of any third-party services before using them. iFasst assumes no liability for any interactions between users and third-party platforms.
        </Text>

        <Heading size="md" mb={-7}>
            11. Platform Modifications
        </Heading>
        <Text mb={-4}>
            iFasst reserves the right to modify, update, or discontinue any features or services on the platform at any time without prior notice. We may also update or alter the smart contracts, staking terms, or reward structures. Users will be notified of significant changes through platform announcements or notifications. Your continued use of iFasst after any modifications indicates your acceptance of the updated terms.
        </Text>

        <Heading size="md" mb={-7}>
            12. Intellectual Property
        </Heading>
        <Text mb={-4}>
            All intellectual property rights related to the iFasst platform, including but not limited to trademarks, logos, and software code, are the property of iFasst or its licensors. You are not permitted to copy, modify, distribute, or use any intellectual property without prior written consent from iFasst.
        </Text>

        <Heading size="md" mb={-7}>
            13. Limitation of Liability
        </Heading>
        <Text mb={-4}>
            To the fullest extent permitted by applicable law, iFasst disclaims all liability for any direct, indirect, incidental, consequential, or punitive damages arising from your use of the platform. This includes, but is not limited to, financial losses, loss of data, or damages caused by delays, errors, or disruptions in service.
        </Text>

        <Heading size="md" mb={-7}>
            14. Indemnification
        </Heading>
        <Text mb={-4}>
            You agree to indemnify and hold harmless iFasst, its affiliates, employees, developers, and partners from any claims, damages, losses, liabilities, or expenses arising from your use of the platform, your violation of these Terms, or any actions taken by you in relation to blockchain transactions.
        </Text>

        <Heading size="md" mb={-7}>
            15. Governing Law
        </Heading>
        <Text mb={-4}>
            These Terms are governed by the laws of the jurisdiction in which iFasst operates. Any disputes arising from your use of the platform or these Terms will be subject to the exclusive jurisdiction of the courts in that jurisdiction.
        </Text>

        <Heading size="md" mb={-7}>
            16. Termination of Service
        </Heading>
        <Text mb={-4}>
            iFasst reserves the right to terminate or suspend your access to the platform at any time for any reason, including but not limited to violations of these Terms. Upon termination, you will no longer be able to access the platform, and any pending or incomplete transactions may be affected.
        </Text>

        <Heading size="md" mb={-7}>
            17. Amendments to Terms and Conditions
        </Heading>
        <Text mb={-4}>
            iFasst may revise or update these Terms from time to time. Any changes will be posted on this page, and significant changes will be communicated to users. Continued use of the platform after any amendments constitutes acceptance of the new Terms. You are encouraged to regularly check this page for updates.
        </Text>

        <Heading size="md" mb={-7}>
            18. Contact Information
        </Heading>
        <Text mb={-4}>
            If you have any questions or concerns regarding these Terms and Conditions, please contact us via the provided support channels. We are committed to addressing any inquiries related to your use of the iFasst platform.
            <br /><br />
            <i>Last Updated: October 6, 2024</i>
            <br />
            By using iFasst, you acknowledge that you have read, understood, and agreed to these Terms and Conditions. If you do not agree, you must cease using the platform immediately.
        </Text>
    </Flex>

    );
};